import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function RadioButtonsGrp({
  options,
  selectedOption,
  onChange,
  selectedLevel,
  title,
  direction,
  selectedDictionary,
}) {
  const renderVerticalLayout = () => (
    <Form className='radio-buttons-row'>
      <h6>{title}</h6>
      {options.map((option) => (
        <Form.Check
          key={option.value}
          type='radio'
          id={`radio-${option.value}`}
          label={option.label}
          value={option.value}
          checked={
            selectedOption === option.value && selectedLevel !== "Compete"
          }
          onChange={onChange}
          disabled={
            selectedLevel === "Compete" && selectedDictionary !== "Sight Words"
          }
        />
      ))}
    </Form>
  );

  const renderHorizontalLayout = () => (
    <Form className='radio-buttons-row'>
      <Row className='d-flex justify-content-center align-items-center'>
        <h6 className='m-0 p-0 text-center'>{title}</h6>
      </Row>
      <Row className='justify-content-center'>
        {options.map((option) => (
          <Col key={option.value}>
            <Form.Check
              type='radio'
              id={`radio-${option.value}`}
              label={option.label}
              value={option.value}
              checked={
                selectedOption === option.value && selectedLevel !== "Compete"
              }
              onChange={onChange}
              disabled={selectedLevel === "Compete"}
            />
          </Col>
        ))}
      </Row>
    </Form>
  );

  return direction === "row"
    ? renderHorizontalLayout()
    : renderVerticalLayout();
}
