export const ThirdGrade = [
  "A",
  "AND",
  "AWAY",
  "BIG",
  "BLUE",
  "CAN",
  "COME",
  "DOWN",
  "FIND",
  "FOR",
  "FUNNY",
  "GO",
  "HELP",
  "HERE",
  "I",
  "IN",
  "IS",
  "IT",
  "JUMP",
  "LITTLE",
  "LOOK",
  "MAKE",
  "ME",
  "MY",
  "NOT",
  "ONE",
  "PLAY",
  "RED",
  "RUN",
  "SAID",
  "SEE",
  "THE",
  "THREE",
  "TO",
  "TWO",
  "UP",
  "WE",
  "WHERE",
  "YELLOW",
  "YOU",
  "ALL",
  "AM",
  "ARE",
  "AT",
  "ATE",
  "BE",
  "BLACK",
  "BROWN",
  "BUT",
  "CAME",
  "DID",
  "DO",
  "EAT",
  "FOUR",
  "GET",
  "GOOD",
  "HAVE",
  "HE",
  "INTO",
  "LIKE",
  "MUST",
  "NEW",
  "NO",
  "NOW",
  "ON",
  "OUR",
  "OUT",
  "PLEASE",
  "PRETTY",
  "RAN",
  "RIDE",
  "SAW",
  "SAY",
  "SHE",
  "SO",
  "SOON",
  "THAT",
  "THERE",
  "THEY",
  "THIS",
  "TOO",
  "UNDER",
  "WANT",
  "WAS",
  "WELL",
  "WENT",
  "WHAT",
  "WHITE",
  "WHO",
  "WILL",
  "WITH",
  "YES",
  "AFTER",
  "AGAIN",
  "AN",
  "ANY",
  "AS",
  "ASK",
  "BY",
  "COULD",
  "EVERY",
  "FLY",
  "FROM",
  "GIVE",
  "GOING",
  "HAD",
  "HAS",
  "HER",
  "HIM",
  "HIS",
  "HOW",
  "JUST",
  "KNOW",
  "LET",
  "LIVE",
  "MAY",
  "OF",
  "OLD",
  "ONCE",
  "OPEN",
  "OVER",
  "PUT",
  "ROUND",
  "SOME",
  "STOP",
  "TAKE",
  "THANK",
  "THEM",
  "THEN",
  "THINK",
  "WALK",
  "WERE",
  "WHEN",
  "ALWAYS",
  "AROUND",
  "BECAUSE",
  "BEEN",
  "BEFORE",
  "BEST",
  "BOTH",
  "BUY",
  "CALL",
  "COLD",
  "DOES",
  "DON'T",
  "FAST",
  "FIRST",
  "FIVE",
  "FOUND",
  "GAVE",
  "GOES",
  "GREEN",
  "ITS",
  "MADE",
  "MANY",
  "OFF",
  "OR",
  "PULL",
  "READ",
  "RIGHT",
  "SING",
  "SIT",
  "SLEEP",
  "TELL",
  "THEIR",
  "THESE",
  "THOSE",
  "UPON",
  "US",
  "USE",
  "VERY",
  "WASH",
  "WHICH",
  "WHY",
  "WISH",
  "WORK",
  "WOULD",
  "WRITE",
  "YOUR",
  "ABOUT",
  "BETTER",
  "BRING",
  "CARRY",
  "CLEAN",
  "CUT",
  "DONE",
  "DRAW",
  "DRINK",
  "EIGHT",
  "FALL",
  "FAR",
  "FULL",
  "GOT",
  "GROW",
  "HOLD",
  "HOT",
  "HURT",
  "IF",
  "KEEP",
  "KIND",
  "LAUGH",
  "LIGHT",
  "LONG",
  "MUCH",
  "MYSELF",
  "NEVER",
  "ONLY",
  "OWN",
  "PICK",
  "SEVEN",
  "SHALL",
  "SHOW",
  "SIX",
  "SMALL",
  "START",
  "TEN",
  "TODAY",
  "TOGETHER",
  "TRY",
  "WARM",
  "APPLE",
  "BABY",
  "BACK",
  "BALL",
  "BEAR",
  "BED",
  "BELL",
  "BIRD",
  "BIRTHDAY",
  "BOAT",
  "BOX",
  "BOY",
  "BREAD",
  "BROTHER",
  "CAKE",
  "CAR",
  "CAT",
  "CHAIR",
  "CHICKEN",
  "CHILDREN",
  "CHRISTMAS",
  "COAT",
  "CORN",
  "COW",
  "DAY",
  "DOG",
  "DOLL",
  "DOOR",
  "DUCK",
  "EGG",
  "EYE",
  "FARM",
  "FARMER",
  "FATHER",
  "FEET",
  "FIRE",
  "FISH",
  "FLOOR",
  "FLOWER",
  "GAME",
  "GARDEN",
  "GIRL",
  "GOODBYE",
  "GRASS",
  "GROUND",
  "HAND",
  "HEAD",
  "HILL",
  "HOME",
  "HORSE",
  "HOUSE",
  "KITTY",
  "LEG",
  "LETTER",
  "MAN",
  "MEN",
  "MILK",
  "MONEY",
  "MORNING",
  "MOTHER",
  "NAME",
  "NEST",
  "NIGHT",
  "PAPER",
  "PARTY",
  "PICTURE",
  "PIG",
  "RABBIT",
  "RAIN",
  "RING",
  "ROBIN",
  "SANTA",
  "CLAUS",
  "SCHOOL",
  "SEED",
  "SHEEP",
  "SHOE",
  "SISTER",
  "SNOW",
  "SONG",
  "SQUIRREL",
  "STICK",
  "STREET",
  "SUN",
  "TABLE",
  "THING",
  "TIME",
  "TOP",
  "TOY",
  "TREE",
  "WATCH",
  "WATER",
  "WAY",
  "WIND",
  "WINDOW",
  "WOOD",
];
