import React from "react";

export default function PlayScoreBar({ score, level, time }) {
  return (
    <div className='play-scorebar-container'>
      <div className='inner-container'>
        <p className='text-sm m-0'> Score:{score}</p>
        <p className='text-sm m-0'>{level}</p>
        {level === "Compete" && <p className='text-sm m-0'>{time}</p>}
      </div>
    </div>
  );
}
