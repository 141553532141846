import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function CompetePopup({
  visible,
  handleOk,
  message = "You can compete once per day.",
}) {
  if (!visible) return null;
  return (
    <>
      <Modal show={visible} centered>
        <Modal.Body className='end-game-modal-content'>
          <p>{message}</p>
        </Modal.Body>
        <div
          className='d-flex justify-content-center align-items-center pb-2'
          style={{
            background: "white",
            borderBottomLeftRadius: 15,
            borderBottomRightRadius: 15,
          }}
        >
          <Button style={{ width: "30%" }} onClick={handleOk}>
            Ok
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default CompetePopup;
