import React, { useState, useEffect, useRef, useCallback } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import OSPD from "../Utilities/OSPD_Dictionary.js";
import Navbar from "../Components/Navbar.js";
import PlayScoreBar from "../Components/PlayScoreBar.js";
import Letters from "../Components/Letters.js";
import EndGameModal from "../Components/EndGameModal.js";
import DeleteSbmitContainer from "../Components/DeleteSbmitContainer.js";
import CrossModal from "../Components/CrossModal.js";
import { addUserScores } from "../Apis/AppApis.js";
import Loading from "../Components/Loading.js";
import {
  calculateUpcomingMidnight,
  formatCountDownTimer,
} from "../Utilities/MyUtilities.js";
import GameOverModal from "../Components/GameOverModal.js";
import SimpleAlert from "../Components/SimpleAlert.js";
import Collins from "../Utilities/CollinsDictionary.js";
import { Pre_K } from "../Utilities/Pre-kDictionary.js";
import { ThirdGrade } from "../Utilities/ThirdGradeDictionary.js";
import { KINDERGARTEN_LETTERS, PRE_K_LETTERS } from "../Utilities/Constants.js";
import { Kindergarten } from "../Utilities/KinderGartenDictionary.js";
import { FirstGrade } from "../Utilities/FirstGradeDictionary.js";
import { SecondGrade } from "../Utilities/SecondGradeDictionary.js";

const CONSONANTS = [
  "B",
  "C",
  "D",
  "F",
  "G",
  "H",
  "J",
  "K",
  "L",
  "M",
  "N",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "V",
  "W",
  "X",
  "Z",
];
const VOWELS = ["A", "E", "I", "O", "U", "Y"];

function useInterval(callback, delay, clearCallback) {
  const [intervalId, setIntervalId] = useState();
  const savedCallback = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    let id = setInterval(tick, delay);
    setIntervalId(id);
    return () => clearInterval(id);
  }, [delay]);

  useEffect(() => {
    if (clearCallback === "clear") {
      clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearCallback]);
}

export default function Play() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [timeElapsed, setTimeElapsed] = useState(0);
  const [timeDisplay, setTimeDisplay] = useState("");
  const [correctWords, setCorrectWords] = useState([]);
  const [incorrectWords, setIncorrectWords] = useState([]);

  const [word, setWord] = useState([]);
  const [level, setLevel] = useState("");
  const [levelComplexity, setLevelComplexity] = useState("Intermediate");

  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [uuid, setUuid] = useState("");
  const [value, setValue] = useState("");
  const [points, setPoints] = useState(1);
  const [score, setScore] = useState(0);
  const [letterIntervalId, setLetterIntervalId] = useState(0);
  const [disabled, setDisabled] = useState();
  const [visible, setVisible] = useState(false);
  const [visibleCross, setVisibleCross] = useState();
  const effectRan = useRef(false);
  const [timeIntervalId, setTimeIntervalId] = useState(0);
  const [timerId, setTimerId] = useState(undefined);
  const [countDownId, setCountDownId] = useState(undefined);
  const [countDownIdCompete, setCountDownIdCompete] = useState(undefined);

  const [gameLetterList, setGameLetterList] = useState([]);
  const [showGameOver, setShowGameOver] = useState(false);
  const [submitBtnTxt, setSubmitBtnTxt] = useState("Submit");
  const [loading, setLoading] = useState(true);
  const [letterSpeed, setLetterSpeed] = useState(1000);
  const [showAlert, setShowAlert] = useState(false);
  const [simpleAlertMsg, setSimpleAlertMsg] = useState("");
  const [selectedDictionary, setSelectedDictionary] = useState("Collins");
  const [lettersPos, setLettersPos] = useState([]);
  const [time, setTime] = useState(180);
  const [formattedTime, setFormattedTime] = useState(
    formatCountDownTimer(time)
  );
  useEffect(() => {
    const levelHolder = localStorage.getItem("LEVEL");

    if (levelHolder !== "Compete") return;

    const interval = setInterval(() => {
      setTime((prevTime) => {
        const newTime = prevTime - 1;
        setFormattedTime(formatCountDownTimer(newTime));

        if (newTime <= 0) {
          clearInterval(interval);
          handleTimeEnd();
        }

        return newTime;
      });
    }, 1000);
    setCountDownIdCompete(interval);

    return () => clearInterval(interval);
  }, []);
  const handleTimeEnd = () => {
    // Your function to be called when the timer reaches 0
    endGame();
    console.log("Time is up!");
  };

  useEffect(() => {
    const isGameDayExist = competeDayExist1();
    const levelHolder = localStorage.getItem("LEVEL");

    if (!isGameDayExist && levelHolder === "Compete") {
      navigate(-1);
      return;
    }

    if (effectRan.current || process.env.NODE_ENV !== "development") {
      setLoading(true);
      makeLetters();
      fetchDataFromStorage();
    }

    return () => {
      effectRan.current = true;
    };
  }, []);

  useInterval(
    () => {
      setTimeElapsed(timeElapsed + 1);
      setTimeDisplay(displayTimer(timeElapsed + 1));
    },
    1000,
    timeIntervalId
  );
  useInterval(
    () => {
      const levelcomplexityHolder = localStorage.getItem("L_COMPLEXITY");
      if (
        levelcomplexityHolder === "Pre-K" ||
        levelcomplexityHolder === "Kindergarten"
      ) {
        doKidsLetters();
      } else {
        doGameDayLetters();
      }
    },
    letterSpeed,
    letterIntervalId
  );

  const competeDayExist1 = () => {
    const lastGameTimestamp = localStorage.getItem("lastGameTimestamp");
    const upcomingMidnight = localStorage.getItem("UPCOMING_MID_NIGHT");
    const currentTimeStamp = new Date().toUTCString();

    if (!lastGameTimestamp) return "false";
    // const upcomingMidnight = calculateUpcomingMidnight();

    const lastGameDate = new Date(lastGameTimestamp);
    const currentGameDate = new Date(currentTimeStamp);
    const upcomgMidnight = new Date(upcomingMidnight);

    if (
      lastGameDate &&
      currentGameDate &&
      upcomgMidnight &&
      currentGameDate >= upcomgMidnight &&
      lastGameDate < upcomgMidnight
    ) {
      return true;
    } else {
      return false;
    }
  };

  const makeLetters = () => {
    const levelcomplexityHolder = localStorage.getItem("L_COMPLEXITY");

    if (levelcomplexityHolder === "Pre-K") {
      setGameLetterList(PRE_K_LETTERS);

      return;
    } else if (levelcomplexityHolder === "Kindergarten") {
      setGameLetterList(KINDERGARTEN_LETTERS);

      return;
    }

    let vowelOne = [...VOWELS];
    let consonantsOne = [...CONSONANTS];
    let lettersHolder = [];

    const vowelOrConsoValueHolder = localStorage.getItem("CONSONANT_PER_VOWEL");
    const vowelOrConsoValue = JSON.parse(vowelOrConsoValueHolder);
    const numOfVowelsOrCons = Math.abs(vowelOrConsoValue) + 1;

    if (vowelOrConsoValue) {
      // The first letter should be a vowel
      // const vowelIndexCopy = Math.floor(Math.random() * vowelOne.length);
      // const vowelRemovedCopy = vowelOne.splice(vowelIndexCopy, 1)[0];
      // lettersHolder.push(vowelRemovedCopy);
    }

    // Continue with the remaining letters
    for (let index = 1; index < 4000; index++) {
      if (vowelOrConsoValue > 0) {
        if (index % numOfVowelsOrCons === 0) {
          if (vowelOne.length === 0) {
            vowelOne = [...VOWELS];
          }
          const vowelIndex = Math.floor(Math.random() * vowelOne.length);
          const vowelRemoved = vowelOne.splice(vowelIndex, 1)[0];

          lettersHolder.push(vowelRemoved);
        } else {
          if (consonantsOne.length === 0) {
            consonantsOne = [...CONSONANTS];
          }
          const consonantsIndex = Math.floor(
            Math.random() * consonantsOne.length
          );
          const consonantsRemoved = consonantsOne.splice(consonantsIndex, 1)[0];

          lettersHolder.push(consonantsRemoved);
        }
      } else if (vowelOrConsoValue < 0) {
        if (index % numOfVowelsOrCons === 0) {
          if (consonantsOne.length === 0) {
            consonantsOne = [...CONSONANTS];
          }
          const consonantsIndex = Math.floor(
            Math.random() * consonantsOne.length
          );
          const consonantsRemoved = consonantsOne.splice(consonantsIndex, 1)[0];

          lettersHolder.push(consonantsRemoved);
        } else {
          if (vowelOne.length === 0) {
            vowelOne = [...VOWELS];
          }
          const vowelIndex = Math.floor(Math.random() * vowelOne.length);
          const vowelRemoved = vowelOne.splice(vowelIndex, 1)[0];

          lettersHolder.push(vowelRemoved);
        }
      } else if (vowelOrConsoValue === 0) {
        if (index % 2 === 0 || index % 4 === 0) {
          if (consonantsOne.length === 0) {
            consonantsOne = [...CONSONANTS];
          }
          const consonantsIndex = Math.floor(
            Math.random() * consonantsOne.length
          );
          const consonantsRemoved = consonantsOne.splice(consonantsIndex, 1)[0];

          lettersHolder.push(consonantsRemoved);
        } else {
          if (vowelOne.length === 0) {
            vowelOne = [...VOWELS];
          }
          const vowelIndex = Math.floor(Math.random() * vowelOne.length);
          const vowelRemoved = vowelOne.splice(vowelIndex, 1)[0];

          lettersHolder.push(vowelRemoved);
        }
      }
    }

    if (state?.compete) {
      setGameLetterList(
        state?.competeInfo?.gameofDayString?.replace(/\s+/g, "")
      );
    } else {
      setGameLetterList(lettersHolder);
    }
  };

  const fetchDataFromStorage = () => {
    try {
      const uuidHolder = localStorage.getItem("UUID");

      const nameHolder = localStorage.getItem("NAME");
      const cityHolder = localStorage.getItem("CITY");
      const levelcomplexityHolder = localStorage.getItem("L_COMPLEXITY");
      const levelHolder = localStorage.getItem("LEVEL");
      const dictionary = localStorage.getItem("DICTIONARY");

      if (!dictionary) {
        setSelectedDictionary("Collins");
      } else {
        if (dictionary === "Sight Words") {
          setSelectedDictionary(levelcomplexityHolder);
        } else {
          setSelectedDictionary(dictionary);
        }
      }

      setUuid(uuidHolder);
      setName(nameHolder);
      setCity(cityHolder);
      setLevel(levelHolder);
      setLevelComplexity(levelcomplexityHolder);

      if (levelcomplexityHolder === "Beginner") {
        setPoints(2);
        setTimeout(() => {
          setLetterSpeed(2000);
          doGameDayLetters();
        }, 1000);
      } else if (levelcomplexityHolder === "Intermediate") {
        setPoints(3);
        setTimeout(() => {
          setLetterSpeed(1500);
          doGameDayLetters();
        }, 1000);
      } else if (levelcomplexityHolder === "Advanced") {
        setPoints(4);

        if (state?.compete) {
          setTimeout(() => {
            setLetterSpeed(state?.competeInfo?.secondsPerLetter * 1000);
            doGameDayLetters();
          }, 1000);
        } else {
          doGameDayLetters();
          setLetterSpeed(1000);
        }
      } else if (levelcomplexityHolder === "Pre-K") {
        setPoints(2);
        setTimeout(() => {
          setLetterSpeed(1000);
          doKidsLetters();
        }, 1000);
      }
    } catch (error) {
      console.log("error in fetchDataFromStorage ", error);
    }
  };

  function success(canScoreBeAdded) {
    clearTimeout(timerId);
    setTimerId(undefined);
    setCountDownId(undefined);
    clearInterval(countDownId);
    setSubmitBtnTxt("Submit");

    setCorrectWords((prevCorrectWords) => [...prevCorrectWords, value]);
    if (
      selectedDictionary === "Pre-K" ||
      selectedDictionary === "Kindergarten"
    ) {
      doRemoveKidsLetters(value);
    } else {
      doRemoveGameLetters(value);
    }

    if (canScoreBeAdded) {
      let scoreIncentive = 1;
      if (value.length < 4) {
        scoreIncentive = 1;
      } else if (value.length === 4 || value.length === 5) {
        scoreIncentive = 2;
      } else if (value.length === 6 || value.length === 7) {
        scoreIncentive = 4;
      } else if (value.length > 7) {
        scoreIncentive = 8;
      }

      setScore((prevScore) => prevScore + value.length * scoreIncentive);
    }
    setValue("");
    setLettersPos([]);
  }

  const fail = () => {
    setVisibleCross(true);
    const incorrectWordsHolder = [...incorrectWords];
    incorrectWordsHolder.push(value);
    localStorage.setItem(`INCORRECT_WORDS`, incorrectWordsHolder);
    setIncorrectWords((prevIncorrectWords) => [...prevIncorrectWords, value]);
    setTimeout(() => {
      setVisibleCross(false);
      setValue("");
      setLettersPos([]);
    }, 300);
  };

  const doRemoveGameLetters = async (enteredWord) => {
    const wordsHolder = [...word];

    const uniqueChars = await removeDuplicateCharacters(enteredWord);

    uniqueChars.split("").forEach((letter) => {
      wordsHolder.forEach((res, i) => {
        if (res[0] === letter) {
          wordsHolder.splice(i, 1);
        }
      });
      setValue("");

      setWord(wordsHolder);
    });
  };

  const doRemoveKidsLetters = async (enteredWord) => {
    const wordsHolder = [...word];
    let filteredArray;
    if (lettersPos.length > 0) {
      filteredArray = wordsHolder.filter(
        (_, index) => !lettersPos.includes(index)
      );
      setWord(filteredArray);
    } else {
      let valueChars = value.split("");

      for (let i = 0; i < valueChars.length; i++) {
        let index = wordsHolder.indexOf(valueChars[i]);
        if (index !== -1) {
          wordsHolder.splice(index, 1); // Remove the character if found in letters array
        }
      }
      setWord(wordsHolder);
    }

    setValue("");
    setLettersPos([]);

    // setWord(filteredArray);
  };

  const endGame = () => {
    const levelHolder = localStorage.getItem("LEVEL");

    console.log("levelHolder ", levelHolder);

    if (levelHolder !== "Compete") {
      navigateGameOver(incorrectWords);
    } else {
      clearInterval(countDownIdCompete);
      clearInterval(countDownId);

      setVisible(true);
    }

    setDisabled(true);
    setLetterIntervalId("clear");
    setTimeIntervalId("clear");
  };
  const doGameDayLetters = () => {
    setLoading(false);

    if (gameLetterList.length < 1) return;
    const lettersLength = word.length;

    if (gameLetterList.length > 1 && gameLetterList.length < 12) {
      setShowAlert(true);
      setSimpleAlertMsg(
        "Well done! you have completed all the compete letters."
      );
      return;
    }

    if (lettersLength >= 12) {
      startCountDown();
      return;
    }

    let gameLettersHolder = [...gameLetterList];
    let letterRemoved;

    // Keep removing letters until a letter not present in 'word' is found
    while (gameLettersHolder.length > 0) {
      letterRemoved = gameLettersHolder.splice(0, 1)[0];

      if (
        word.includes(letterRemoved) &&
        VOWELS.includes(letterRemoved.toUpperCase())
      ) {
        // If the removed letter is a vowel in the word, find another vowel not in the word
        let replacementVowel = VOWELS.find((vowel) => !word.includes(vowel));
        let replacementConsonants = CONSONANTS.find(
          (consonant) => !word.includes(consonant)
        );

        if (replacementVowel) {
          letterRemoved = replacementVowel.toUpperCase(); // Replace with the new vowel
          break;
        } else {
          letterRemoved = replacementConsonants.toUpperCase(); // Replace with the new vowel
          break;
        }
      } else if (!word.includes(letterRemoved)) {
        break;
      }
    }

    setGameLetterList(gameLettersHolder);

    if (!word.includes(letterRemoved)) {
      setWord([...word, letterRemoved]);
    }
  };

  const doKidsLetters = () => {
    setLoading(false);

    if (gameLetterList.length < 1) return;
    const lettersLength = word.length;

    if (gameLetterList.length > 1 && gameLetterList.length < 12) {
      setShowAlert(true);
      setSimpleAlertMsg("Well done! you have completed all the letters.");
      return;
    }

    if (lettersLength >= 12) {
      startCountDown();
      return;
    }

    let gameLettersHolder = [...gameLetterList];

    let letterRemoved;

    letterRemoved = gameLettersHolder.splice(0, 1)[0];

    setGameLetterList(gameLettersHolder);

    setWord([...word, letterRemoved]);
  };

  const displayTimer = (seconds) => {
    let minutes = parseInt(seconds / 60);
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    let secondsRemaining = parseInt(seconds % 60);
    if (secondsRemaining < 10) {
      secondsRemaining = "0" + secondsRemaining;
    }

    return minutes + ":" + secondsRemaining;
  };

  const startCountDown = () => {
    let delay = 0;
    switch (levelComplexity) {
      case "Beginner":
        delay = 7000;
        break;
      case "Intermediate":
        delay = 5000;
        break;
      case "Advanced":
        if (state?.compete) {
          delay = state?.competeInfo?.secondsAtTheEnd * 1000;
        } else {
          delay = 3000;
        }
        break;

      default:
        delay = 15000;
    }
    if (!!timerId) {
      return;
    }
    let timeLeft = delay / 1000;
    setSubmitBtnTxt(`${timeLeft}`);
    const intervalId = setInterval(() => {
      timeLeft--;

      if (timeLeft < 0) {
        clearInterval(intervalId);
      } else {
        setSubmitBtnTxt(`${timeLeft}`);
      }
    }, 1000);
    setCountDownId(intervalId);

    const timer = setTimeout(() => {
      endGame();
    }, delay);
    setTimerId(timer);
  };

  const press = useCallback((data, itemIndex) => {
    if (Boolean(data)) {
      setValue((v) => v + data);
      if (itemIndex > -1) {
        setLettersPos((prevPositions) => [...prevPositions, itemIndex]);
      }
    }
  }, []);

  const clear = () => {
    setValue(value.slice(0, -1));
    setLettersPos([]);
  };
  const clearAllLetters = () => {
    setValue("");
    setLettersPos([]);
  };

  const handleSubmit = () => {
    const count = word.reduce(
      (total, w) => total + value.split("").filter((v) => v === w[0]).length,
      0
    );

    if (
      count === value.length &&
      (selectedDictionary === "Collins" || selectedDictionary === "OSPD")
    ) {
      const text = value.toUpperCase();

      const results =
        selectedDictionary === "Collins"
          ? Collins.filter((Word) => Word === text)
          : selectedDictionary === "OSPD"
          ? OSPD.filter((Word) => Word === text)
          : selectedDictionary === "First Grade"
          ? FirstGrade.filter((Word) => Word === text)
          : selectedDictionary === "Second Grade"
          ? SecondGrade.filter((Word) => Word === text)
          : ThirdGrade.filter((Word) => Word === text);

      if (results.length !== 0) {
        success(results.length > 0);
      } else {
        fail();
      }
    } else if (
      selectedDictionary === "Pre-K" ||
      selectedDictionary === "Kindergarten" ||
      selectedDictionary === "First Grade" ||
      selectedDictionary === "Second Grade" ||
      selectedDictionary === "Third Grade"
    ) {
      const text = value.toUpperCase();

      const results =
        selectedDictionary === "Pre-K"
          ? Pre_K.filter((Word) => Word === text)
          : selectedDictionary === "Kindergarten"
          ? Kindergarten.filter((Word) => Word === text)
          : selectedDictionary === "First Grade"
          ? FirstGrade.filter((Word) => Word === text)
          : selectedDictionary === "Second Grade"
          ? SecondGrade.filter((Word) => Word === text)
          : ThirdGrade.filter((Word) => Word === text);

      let isCollinWord = [];
      let isOSPDWord = [];
      if (results.length === 0) {
        isCollinWord = Collins.filter((Word) => Word === text);
        isOSPDWord = OSPD.filter((Word) => Word === text);
      }

      if (
        results.length !== 0 ||
        isCollinWord.length !== 0 ||
        isOSPDWord.length !== 0
      ) {
        success(results.length > 0);
      } else {
        fail();
      }
    } else {
      fail();
    }
  };

  const onOk = async (isBackBtnClick) => {
    if (score === 0) return navigateGameOver(isBackBtnClick);
    setVisible(false);
    setLoading(true);
    try {
      const userInfo = {
        name: name,
        city: city,
        uuId: uuid,
        score: score,
        dictionary: selectedDictionary,
      };

      const response = await addUserScores(userInfo);
      navigateGameOver(isBackBtnClick);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const onNo = () => {
    setVisible(false);
    navigateGameOver();
  };

  const navigateGameOver = (shouldNotGoGameOver) => {
    const incorrectWordsHolder = localStorage.getItem(`INCORRECT_WORDS`);
    const levelHolder = localStorage.getItem("LEVEL");

    localStorage.removeItem(`INCORRECT_WORDS`);

    const myScore = {
      correctWords,
      timeDisplay,
      incorrectWords: incorrectWordsHolder
        ? incorrectWordsHolder?.split(",")
        : [],
      level: levelHolder === "Compete" ? levelHolder : levelComplexity,
      name,
      points,
      score,
      city,
      date: new Date(),
      compete: state?.compete,
    };

    if (levelHolder === "Compete" && correctWords?.length > 0 && score !== 0) {
      const currentTimestamp = new Date().toUTCString();
      const upcomingMidnight = calculateUpcomingMidnight();
      localStorage.setItem("lastGameTimestamp", currentTimestamp);
      localStorage.setItem("UPCOMING_MID_NIGHT", upcomingMidnight);
    }

    if (
      (correctWords?.length > 0 || incorrectWords?.length > 0) &&
      levelHolder !== "Compete"
    ) {
      saveScore(myScore);
    }
    setLoading(false);

    if (shouldNotGoGameOver === true) {
      navigate("/");
    } else {
      navigate("/GameOver", {
        state: myScore,
      });
    }
  };

  const saveScore = (myScore) => {
    try {
      let allScores = [];

      const scoreHolder = localStorage.getItem("MY_SCORES");
      if (scoreHolder) {
        allScores = JSON.parse(scoreHolder);
      }

      allScores.unshift(myScore);

      localStorage.setItem("MY_SCORES", JSON.stringify(allScores));
    } catch (error) {
      console.error("Error while saving score:", error);
    }
  };

  const removeDuplicateCharacters = (string) => {
    return new Promise((resolve) => {
      const unique = [...new Set(string)];
      resolve(unique.join(""));
    });
  };

  const handleKeyPress = (event) => {
    const item = event?.key;
    if (item === "Backspace") {
      clear();
      return;
    } else if (item === "Enter") {
      handleSubmit();
      return;
    } else if (!word.includes(item.toUpperCase())) {
      return;
    } else if (value?.length >= 10) {
      return;
    } else {
      press(item.toUpperCase());
    }
  };
  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [
    value,
    word,
    timerId,
    countDownId,
    countDownIdCompete,
    submitBtnTxt,
    correctWords,
    score,
    gameLetterList,
  ]);

  const handleBack = async () => {
    setLoading(true);
    await onOk(true);
    clearTimeout(timerId);
    setTimerId(undefined);
    setCountDownId(undefined);
    setCountDownIdCompete(undefined);
    clearInterval(countDownId);
    clearInterval(countDownIdCompete);
    setLoading(false);
  };

  return (
    <div>
      <Loading load={loading} />
      <SimpleAlert
        message={simpleAlertMsg}
        show={showAlert}
        setShow={setShowAlert}
        nextPage={() => navigate("/")}
      />
      <Navbar title='MaxWord' onBack={handleBack} />
      <PlayScoreBar
        score={score}
        level={level === "Compete" ? level : levelComplexity}
        time={formattedTime}
      />

      <div className='entered-word-container'>
        <div>
          <span className='text-uppercase entered-word '>{value}</span>
          <hr className='horizontal-line' />
        </div>
      </div>

      <Letters
        word={word}
        onClickLetter={(item, itemIndex) => {
          if (value?.length >= 10) return;

          press(item, itemIndex);
        }}
        disabled={disabled}
      />

      <CrossModal visible={visibleCross} />

      <DeleteSbmitContainer
        disableBtns={value?.length > 0 ? false : true}
        handleDelete={clear}
        handleClear={clearAllLetters}
        handleSubmit={handleSubmit}
        submitBtnTitle={submitBtnTxt}
      />

      <EndGameModal visible={visible} handleYes={onOk} />
      <GameOverModal visible={showGameOver} handleOk={navigateGameOver} />
    </div>
  );
}
