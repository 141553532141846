import Main from "../Pages/Main";
import Play from "../Pages/Play";
import Help from "../Pages/Help";
import GameOver from "../Pages/GameOver";
import MyScores from "../Pages/MyScores";
import TopScores from "../Pages/TopScores";
import Options from "../Pages/Options";
import FriendsGroup from "../Pages/FriendsGroup";

export const ROUTES = [
  {
    path: "/",
    isPrivate: false,
    Component: Main,
    tabTitle: "MaxWord",
  },
  {
    path: "/Play",
    isPrivate: false,
    Component: Play,
    tabTitle: "Play",
  },
  {
    path: "/Help",
    isPrivate: false,
    Component: Help,
    tabTitle: "Help",
  },

  {
    path: "/GameOver",
    isPrivate: false,
    Component: GameOver,
    tabTitle: "Game Over",
  },
  {
    path: "/MyScores",
    isPrivate: false,
    Component: MyScores,
    tabTitle: "My Scores",
  },
  {
    path: "/TopScores",
    isPrivate: false,
    Component: TopScores,
    tabTitle: "Top Scores",
  },
  {
    path: "/Options",
    isPrivate: false,
    Component: Options,
    tabTitle: "Options",
  },
  {
    path: "/FriendsGroup",
    isPrivate: false,
    Component: FriendsGroup,
    tabTitle: "FriendsGroup",
  },
];
