import "./App.css";
import "./Sass/index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { ROUTES } from "./Routes";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import RouteContainer from "./Routes/RouteContainer";
import { ThemeProvider } from "react-bootstrap";

library.add(fas);

function App() {
  return (
    <ThemeProvider
      breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
      minBreakpoint='xxs'
    >
      <div>
        <Router>
          <Routes>
            {ROUTES.map(({ Component, isPrivate, path, roles, tabTitle }) => (
              <Route
                exact
                path={path}
                key={path}
                element={
                  <RouteContainer tabTitle={tabTitle}>
                    <Component />
                  </RouteContainer>
                }
              />
            ))}
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
