// src/api/axiosInstance.js
import axios from "axios";
const baseURL = "https://backend.maxword.net/.netlify/functions/server/api";
// const baseURL = "http://localhost:8888/.netlify/functions/server/api";

const instance = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default instance;
