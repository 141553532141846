import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BottomButtons, { BottomButtonGroup } from "../Components/BottomButtons";
import WordList from "../Components/WordsList";
import InfoRow from "../Components/InfoRow";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "../Components/Button";
import Navbar from "../Components/Navbar";
import SimpleAlert from "../Components/SimpleAlert";
import Toast from "../Components/Toast";
import { convertSpaces } from "../Utilities/MyUtilities";
import { Button as BootStrpButton } from "react-bootstrap";

import { PRODUCTION_URL } from "../Apis/URLs";

export default function GameOverCard() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [navigationPath, setNavigationPath] = useState();
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  let groupId = localStorage.getItem("GROUP_ID");
  let groupName = localStorage.getItem("GROUP_NAME");
  const handleGroupScore = () => {
    if (!groupId) {
      setShowAlert(true);
      setAlertMsg("You have no group at this time.");
      setNavigationPath();

      return;
    }
    navigate("/TopScores", {
      state: { groupName: groupName, groupId: groupId },
    });
  };
  const copyToClipboard = () => {
    const textToCopy = groupId
      ? `score : ${state?.score} 
    Level : ${state?.level} 
    Time : ${state?.timeDisplay} 
    Correct : ${state?.correctWords?.length} 
    Incorrect : ${state?.incorrectWords?.length} 
    Link :   ${
      PRODUCTION_URL +
      "?name=" +
      convertSpaces(groupName, true) +
      "&id=" +
      groupId
    }`
      : `
    score : ${state?.score} 
    Level : ${state?.level} 
    Time : ${state?.timeDisplay} 
    Correct : ${state?.correctWords?.length} 
    Incorrect : ${state?.incorrectWords?.length} 
    Link :  maxword.net`;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setTimeout(() => {
          setIsLinkCopied(false);
        }, 3000);
        setIsLinkCopied(true);
      })
      .catch((error) => {
        setIsLinkCopied(false);

        console.error("Unable to copy text: ", error);
        alert("Copy to clipboard failed. Please copy the text manually.");
      });
  };
  // useEffect(() => {
  //   window.onpopstate = () => {
  //     navigate(-2);
  //   };
  // }, []);

  return (
    <div>
      <Navbar title={"Game Over"} backPage='/' />
      {isLinkCopied && <Toast message='Copied to clipboard' duration={3000} />}
      <SimpleAlert
        show={showAlert}
        setShow={setShowAlert}
        message={alertMsg}
        navigationPath={navigationPath}
      />

      <div className='game-over-card'>
        <div className='game-over-card-info'>
          <InfoRow label='Score' value={state?.score} />
          <InfoRow label='Level' value={state?.level} />
          <InfoRow label='Time' value={state?.timeDisplay} />

          <div className='words-container'>
            <WordList label='Correct' words={state?.correctWords} />
          </div>
          <WordList label='Incorrect' words={state?.incorrectWords} />
        </div>
        <BootStrpButton
          className='mb-2 btn-friend-grp'
          onClick={copyToClipboard}
          variant='primary'
        >
          {"Share"}
          <FontAwesomeIcon className='ml-1' icon='fa-solid fa-share-nodes' />
        </BootStrpButton>
      </div>

      <div className='mt-5'>
        <BottomButtonGroup
          buttons={[
            {
              label: "Top Scores",
              icon: "-solid fa-bars",
              onClick: () =>
                navigate("/TopScores", {
                  state: { gameDay: true },
                }),
            },
            {
              label: "Friends Scores",
              icon: "solid fa-people-group",
              onClick: () => handleGroupScore(),
            },
            {
              label: "My Scores",
              icon: "solid fa-ranking-star",
              onClick: () => navigate("/MyScores"),
            },

            {
              label: "Play",
              icon: "-solid fa-play",
              onClick: () => navigate("/Options"),
            },
          ]}
        />
      </div>
    </div>
  );
}
