import React, { useEffect, useState, useRef } from "react";
import Navbar from "../Components/Navbar";
import FormInput from "../Components/FormInput";
import { FormProvider, useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { groupSchema } from "../Utilities/Validations";
import {
  addUserScores,
  createGroup,
  joinGroup,
  leaveGroup,
} from "../Apis/AppApis";
import { useNavigate } from "react-router-dom";
import Loading from "../Components/Loading";
import { useLocation } from "react-router-dom";
import { LOCAL_URL, PRODUCTION_URL } from "../Apis/URLs";
import JoinGroupModal from "../Components/JoinGroupModal";
import UseEffectUtility from "../Utilities/CustomHooks";
import SimpleAlert from "../Components/SimpleAlert";
import { convertSpaces } from "../Utilities/MyUtilities";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Toast from "../Components/Toast";

export default function FriendsGroup() {
  const { state } = useLocation();

  const [groupName, setGroupName] = useState(
    state?.groupName ? convertSpaces(state.groupName, false) : ""
  );
  const [groupId, setGroupId] = useState(state?.groupId ? state.groupId : "");
  const [loading, setLoading] = useState(false);
  const [uuid, setUuid] = useState("");
  const [showJoinModal, setShowJoinModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [navigationPath, setNavigationPath] = useState("/");
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const groupLinkRef = useRef(null);

  const methods = useForm({
    resolver: yupResolver(groupSchema),
  });

  const navigate = useNavigate();
  UseEffectUtility(() => {
    const uuidHolder = localStorage.getItem("UUID");
    const groupNameHolder = localStorage.getItem("GROUP_NAME");
    const groupIdHolder = localStorage.getItem("GROUP_ID");
    setUuid(uuidHolder);
    if (state?.groupId === groupIdHolder) {
      return;
    } else if (state?.groupId && groupIdHolder) {
      setShowAlert(true);

      setAlertMessage(
        "You are already in a group please leave that group to participate in this group."
      );
      setNavigationPath("/");

      return;
    }
    if (state?.groupId) {
      if (!uuidHolder) {
        setShowJoinModal(true);
      } else {
        joinFriendsGroup(uuidHolder);
      }
    }

    if (groupNameHolder && !state?.groupName) setGroupName(groupNameHolder);
    if (groupIdHolder && !state?.groupId) setGroupId(groupIdHolder);
  }, []);

  const createFriendsGroup = async () => {
    try {
      setLoading(true);
      const resp = await createGroup({
        groupName: groupName,
        ...(uuid && { userId: uuid }),
      });
      setGroupId(resp?.group?.groupId);

      localStorage.setItem("GROUP_NAME", resp?.group?.name);
      localStorage.setItem("GROUP_ID", resp?.group?.groupId);
      setLoading(false);
    } catch (error) {
      console.log("error ", error);
      if (error === "A group with that name already exists") {
        setShowAlert(true);
        setAlertMessage(error);
        setNavigationPath();
      }
      console.log("Failed to create group " + error);
      setLoading(false);
    }
  };

  const leaveFriendsGroup = async () => {
    try {
      setLoading(true);

      const uuid = localStorage.getItem("UUID");

      const resp = await leaveGroup({
        groupId: groupId,
        userId: uuid,
      });
      setGroupId("");
      setGroupName("");

      localStorage.removeItem("GROUP_NAME");
      localStorage.removeItem("GROUP_ID");
      setLoading(false);
    } catch (error) {
      console.log("Failed to leave group " + error);
      setLoading(false);
    }
  };

  const handleJoinGroup = async (values) => {
    setShowJoinModal(false);
    try {
      setLoading(true);
      const userUUID = await addUser(values);
      await joinFriendsGroup(userUUID);
      setUuid(userUUID);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.log("error handleJoinGroup ", error);
    }
  };

  const joinFriendsGroup = async (userUUID) => {
    try {
      setLoading(true);

      const payload = {
        groupId: state?.groupId,
        groupName: convertSpaces(state?.groupName, false),
        userId: userUUID,
      };
      const joinGrpResponse = await joinGroup(payload);
      localStorage.setItem("GROUP_NAME", joinGrpResponse?.group?.name);
      localStorage.setItem("GROUP_ID", joinGrpResponse?.group?.groupId);
      setLoading(false);
    } catch (error) {
      if (error === "Group not found") {
        setShowAlert(true);
        setAlertMessage(
          "That Group does not exist. Create a new group or make sure the link is correct."
        );
        setNavigationPath("/");
      } else {
        setShowAlert(true);
        setAlertMessage("something went wrong while joining a group ", error);
        setNavigationPath("/");
      }

      setLoading(false);
    }
  };

  const addUser = async (initialInfo) => {
    try {
      const userInfo = {
        name: initialInfo?.name,
        city: initialInfo?.city,
      };
      setLoading(true);
      const response = await addUserScores(userInfo);

      localStorage.setItem("NAME", response?.message?.name);
      localStorage.setItem("CITY", response?.message?.city);
      localStorage.setItem("UUID", response?.message?.id);

      setLoading(false);
      return response?.message?.id;
    } catch (error) {
      setLoading(false);
    }
  };

  const copyToClipboard = () => {
    const textToCopy = groupLinkRef.current.innerText;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setTimeout(() => {
          setIsLinkCopied(false);
        }, 3000);
        setIsLinkCopied(true);
      })
      .catch((error) => {
        setIsLinkCopied(false);

        console.error("Unable to copy text: ", error);
        alert("Copy to clipboard failed. Please copy the text manually.");
      });
  };

  return (
    <div>
      <JoinGroupModal handleJoin={handleJoinGroup} visible={showJoinModal} />
      <Loading load={loading} />
      <SimpleAlert
        show={showAlert}
        setShow={setShowAlert}
        message={alertMessage}
        navigationPath={navigationPath}
      />
      {isLinkCopied && (
        <Toast message='Copied URL to clipboard' duration={3000} />
      )}

      <Navbar title='Friends Group' backPage='/' />
      {groupId ? (
        <div className='d-flex align-items-center flex-column'>
          <div className='d-flex direction-row align-items-center'>
            <p className='fw-bolder text-center  mr-1 mb-1'>Your Group :</p>
            <p className='fw-bolder text-center  mr-1 mb-1 text-primary'>
              {groupName}
            </p>
          </div>
          <div>
            <p className='fw-bolder text-center mr-1 mb-0'>
              Share your group link with friends
            </p>
            <p
              ref={groupLinkRef}
              className='fw-bolder text-center mr-1 text-primary'
            >
              {PRODUCTION_URL +
                "?name=" +
                convertSpaces(groupName, true) +
                "&id=" +
                groupId}
            </p>
          </div>
          <Button
            className='mb-2 btn-friend-grp'
            onClick={copyToClipboard}
            variant='primary'
          >
            {"Share"}
            <FontAwesomeIcon className='ml-1' icon='fa-solid fa-share-nodes' />
          </Button>

          <Button
            onClick={() =>
              navigate("/TopScores", { state: { groupName, groupId } })
            }
            className='mb-2 btn-friend-grp'
          >
            Friends Scores
          </Button>
          {uuid && (
            <Button onClick={leaveFriendsGroup} className='mb-2 btn-friend-grp'>
              Leave Group
            </Button>
          )}
        </div>
      ) : (
        <div className='d-flex align-items-center flex-column p-2'>
          <div className='mr-3'>
            <p className='m-0 text-left'>
              You are currently not in any group on this device. If you want to
              create a group, enter the name. If you want to join an existing
              group, ask them for the URL to join.
            </p>
          </div>
          <div>
            <FormProvider {...methods}>
              <Form onSubmit={methods.handleSubmit(createFriendsGroup)}>
                <div className='d-flex align-items-center flex-column'>
                  <p className='fw-bolder text-center my-3 mr-1 mb-1'>
                    Name Your Group
                  </p>
                  <FormInput
                    id='groupName'
                    name='groupName'
                    value={groupName}
                    placeholder='Enter Group Name'
                    onChange={(e) => setGroupName(e.target.value)}
                  />
                </div>
                <div className='d-flex flex-row justify-content-center mt-3'>
                  <Button type='submit' className='w-100 btn-ok' id='ok'>
                    Submit
                  </Button>
                </div>
              </Form>
            </FormProvider>
          </div>
        </div>
      )}
    </div>
  );
}
