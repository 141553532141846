// AuthContext.js
import React, { createContext, useContext, useState } from "react";

const Context = createContext();

export const ContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const updateUserDetails = (userData) => {
    setUser(userData);
  };
  const clearUserDetails = (userData) => {
    setUser(userData);
  };

  return (
    <Context.Provider value={{ user, updateUserDetails, clearUserDetails }}>
      {children}
    </Context.Provider>
  );
};

export const useContexts = () => {
  return useContext(Context);
};
