import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

export default function DiscreteSliderSteps({ gameInfo }) {
  const [isSliderActive, setIsSliderActive] = useState(true);
  const [initialValue, setInitialValue] = useState(0);
  useEffect(() => {
    localStorage.setItem("CONSONANT_PER_VOWEL", 0);
    setIsSliderActive(gameInfo?.level !== "Compete");
  }, [gameInfo]);

  const getValue = (value) => {
    localStorage.setItem("CONSONANT_PER_VOWEL", value);
  };

  return (
    <div className='d-flex direction-row align-items-center justify-content-center ml-1'>
      <h6
        className='most-vowels p-0 mt-0 mb-0 ml-0'
        style={{
          color: isSliderActive ? "black" : "gray",
        }}
      >
        Vowels
      </h6>
      <Box
        sx={{
          width: 80,
          marginBottom: 0.8,
          marginRight: 1,
          marginLeft: 1,
        }}
      >
        <Slider
          className='m-0 p-0 custom-slider'
          aria-label='Small steps'
          defaultValue={initialValue}
          getAriaValueText={getValue}
          step={1}
          marks
          min={-4}
          max={4}
          disabled={!isSliderActive}
          //   valueLabelDisplay='auto'
        />
      </Box>
      <h6
        className='most-vowels p-0 mt-0 mb-0 mr-0'
        style={{
          color: isSliderActive ? "black" : "gray",
        }}
      >
        Consonants
      </h6>
    </div>
  );
}
