/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import { formatDate } from "../Utilities/MyUtilities";
import MyScore from "../Components/MyScore";

export default function MyScores() {
  const [scores, setScores] = useState([]);

  useEffect(() => {
    const scoresHolder = localStorage.getItem("MY_SCORES");
    if (scoresHolder) setScores(JSON.parse(scoresHolder));
  }, []);

  return (
    <div className='my-scores-container'>
      <Navbar title={"My Practice Scores"} backPage='/' />
      <div className='scores-list'>
        <MyScore scoreList={scores} />
      </div>
    </div>
  );
}
