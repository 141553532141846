import React from "react";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CrossModal = ({ visible }) => {
  return (
    <Modal show={visible} centered animation={false}>
      <Modal.Body className='custom-modal-body'>
        <div className='modal-cross'>
          <FontAwesomeIcon icon='fa-solid fa-xmark' />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CrossModal;
