import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function SimpleAlert({
  message = "",
  secondMessage = "",
  show,
  setShow,
  navigationPath,
  nextPage,
}) {
  const navigate = useNavigate();

  const closeDialog = () => {
    setShow(false);
    if (nextPage) return nextPage();
    if (navigationPath) navigate(navigationPath);
  };

  return (
    <div>
      <Modal show={show} onHide={closeDialog} centered>
        <Modal.Body
          style={{
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6>{message}</h6>
          <h6 className='my-2'>{secondMessage}</h6>
        </Modal.Body>

        <Button variant='primary' onClick={closeDialog}>
          OK
        </Button>
      </Modal>
    </div>
  );
}

export default SimpleAlert;
