import React from "react";
import Spinner from "react-bootstrap/Spinner";

function Loading({ load }) {
  if (!load) {
    return null; // If load is false, don't render anything
  }

  return (
    <div className='loading-overlay'>
      <div className='loading-spinner'>
        <Spinner animation='border' role='status'>
          {/* <span className='loading-spinner'>Loading...</span> */}
        </Spinner>
      </div>
    </div>
  );
}

export default Loading;
