// src/api/postsApi.js
import axios from "axios";
import axiosInstance from "./AxiosInstance";
import { API_KEY, FILE_ID } from "./URLs";

export const addUserScores = async (postData) => {
  try {
    const response = await axiosInstance.post("/addUser", postData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchCompeteLetters = async (uuid) => {
  try {
    // const response = await axiosInstance.get(`/fetchGameString?uuid=${uuid}`);
    const response = await axiosInstance.get(`/fetchGameString`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const fetchCompeteLetters2 = async (uuid) => {
  try {
    // const response = await axiosInstance.get(`/fetchGameString?uuid=${uuid}`);
    const response = await axiosInstance.get(`/fetchGameString2`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchCompeteScores = async () => {
  try {
    const response = await axiosInstance.get(`/top10Scores`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createGroup = async (payload) => {
  try {
    const response = await axiosInstance.post("/createGroup", payload);
    return response.data;
  } catch (error) {
    if (
      error?.response?.data?.error === "A group with that name already exists"
    )
      throw error?.response?.data?.error;

    throw error;
  }
};
export const joinGroup = async (payload) => {
  try {
    const response = await axiosInstance.post("/joinGroup", payload);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.error === "Group not found")
      throw error?.response?.data?.error;

    throw error?.response?.data?.error;
  }
};
export const leaveGroup = async (payload) => {
  try {
    const response = await axiosInstance.post("/leaveGroup", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchGroupScores = async (groupId) => {
  try {
    const response = await axiosInstance.get(`/groupScores/` + groupId);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const updateGroup = async (groupId, userId) => {
  try {
    const response = await axiosInstance.put(`/updateGroup/` + groupId, {
      userId: userId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const updateUser = async (userId, name, city) => {
  try {
    const response = await axiosInstance.put(`/updateUser/` + userId, {
      name,
      city,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getGameStringFrmFile = async (groupId) => {
  try {
    const response = await axios.get(
      `https://www.googleapis.com/drive/v3/files/${FILE_ID}/export?mimeType=text/plain&key=${API_KEY}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
