import React, { useState } from "react";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Form, Button, Modal } from "react-bootstrap";
import { userSchema } from "../Utilities/Validations";
import FormInput from "./FormInput";

export default function JoinGroupModal({ visible, handleJoin }) {
  const [initialInfo, setInitialInfo] = useState({
    name: "",
    city: "",
  });
  const methods = useForm({
    resolver: yupResolver(userSchema),
  });
  const handleChange = (name, e) => {
    console.log("e.target.value ", e.target.value);
    if (name === "name") {
      setInitialInfo({
        ...initialInfo,
        name: e.target.value,
      });
    } else if (name === "city") {
      setInitialInfo({
        ...initialInfo,
        city: e.target.value,
      });
    }
  };

  if (!visible) return null;

  return (
    <>
      <Modal show={visible} centered>
        <Modal.Body className='end-game-modal-content'>
          <h6>Please Enter your Name and City to join this group</h6>
        </Modal.Body>
        <div
          className='centered-div bg-white p-5'
          style={{ borderBottomRightRadius: 10, borderBottomLeftRadius: 10 }}
        >
          <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(handleJoin)}>
              <div>
                <p className='fw-bolder text-center mb-3'>Enter your name :</p>
                <FormInput
                  id='name'
                  name='name'
                  value={initialInfo.name}
                  placeholder='Name'
                  onChange={(e) => handleChange("name", e)}
                />
              </div>
              <div>
                <p className='fw-bolder text-center my-3'>Enter your city :</p>
                <FormInput
                  id='city'
                  name='city'
                  value={initialInfo.city}
                  placeholder='City'
                  onChange={(e) => handleChange("city", e)}
                />
              </div>
              <div className='d-flex flex-row justify-content-center mt-3'>
                <Button type='submit' className='w-100 btn-ok' id='ok'>
                  Join
                </Button>
              </div>
            </Form>
          </FormProvider>
        </div>
      </Modal>
    </>
  );
}
