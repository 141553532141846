import React, { useState, useEffect } from "react";

const Toast = ({ message, duration = 2000 }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, duration);

    return () => {
      clearTimeout(timer);
    };
  }, [duration]);

  return (
    <div className={`toast ${visible ? "show" : ""}`}>
      <div className='toast-message'>{message}</div>
    </div>
  );
};

export default Toast;
