import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../Components/Navbar";
import OptionsHelp from "../Components/OptionsHelp";
import KidsHelp from "../Components/KidsHelp";

export default function Help() {
  const { state } = useLocation();
  console.log("state?.helpContent ", state);
  if (state?.helpContent === "Sight Words") {
    return <KidsHelp />;
  } else if (state?.helpContent === "Options") {
    return <OptionsHelp />;
  }

  return (
    <div>
      <Navbar title='Help' backPage='/' />
      <div className='container-help pl-5 pr-5'>
        <p className='help-text-bold'>General Information:</p>

        <ul className='help-text-list'>
          <li>
            Use touch or your keyboard to create valid words using the letters
            that appear. You can enter words at any time.
          </li>

          <li>Valid words clear the tiles of letters used.</li>
          <li>
            Once all 12 tiles are filled, you've got a few seconds before it’s
            game over!
          </li>
          <li>You can use the same letter multiple times in a word.</li>
          <li>Aim for longer words to get higher scores.</li>
          <li>
            Scoring: Your score totals each correct word's length multiplied by
            a factor.
          </li>

          <ul className='unfilled-bullet-list'>
            <li>2 and 3 letter words, word length multiplied by 1</li>
            <li>4 or 5 letter words, word length multiplied by 2</li>
            <li>6 or 7 letter words, word length multiplied by 4</li>
            <li> {"> "}7 letter words, word length multiplied by 8</li>
          </ul>
          <li>The time zone for the daily competition is GMT.</li>
        </ul>

        <p className='help-text-bold'>Modes:</p>
        <ol>
          <li>
            Practice: Play endlessly at Beginner (slow), Intermediate, or
            Advanced (fast) levels.
          </li>
          <li>
            Compete: Take on the daily challenge against all users. Each user
            will get the same string of letters for that day. The Game of the
            Day is played at the Advanced Level. The level of difficulty will
            fluctuate from day to day with the number of vowels and consonants,
            and a varying amount of time between letters and at the end of the
            game.
          </li>
          <li>
            Compete Against Friends: Same as Compete but quickly let’s you see
            how you did against your friends.
          </li>
        </ol>

        <p className='help-text-bold'>Friends Group:</p>
        <ul className='help-text-list'>
          <li>Create a Friends Group and share the link with whomever.</li>
          <li>
            Or join an existing Friends Group and ask them to send you the link.
          </li>
          <li>
            If you use multiple devices, you will need to click on the link on
            each device.
          </li>
          <li>Leave a Friend’s Group at any time.</li>
          <li>You can only be in one Friends Group at a time on a device.</li>
        </ul>

        <p className='help-text-bold'>Technical Requirements:</p>
        <ul className='help-text-list'>
          <li>
            No app download or login is needed. Just go to{" "}
            <a
              href='maxword.net'
              style={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              maxword.net
            </a>{" "}
            from any device and play!
          </li>
          <li>Use your touch screen or your keyboard.</li>

          <li>Have to be online to play.</li>
          <li>
            Need to allow your Chrome, Safari or other browsers to save cookies.
          </li>
          <li>
            No personal information is collected. You can choose to enter any
            name and city for the leaderboard.
          </li>
          <li>Choose your dictionary: Collins or OSPD (North America)</li>
        </ul>

        <p className='help-text-regular'>
          Discuss the game on Facebook -{" "}
          <a
            href='https://www.facebook.com/maxwordapp/'
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            https://www.facebook.com/maxwordapp/
          </a>
        </p>

        <p className='help-text-regular'>
          Missing words, feedback or other issues? Reach out to us at{" "}
          <a
            href='mailto:maxword@vimware.com'
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            veronicamadsen@yahoo.com
          </a>
          . We appreciate your insights and suggestions!
        </p>
      </div>
    </div>
  );
}
