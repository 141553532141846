import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import { Form, Button, ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from "uuid";
import UseEffectUtility from "../Utilities/CustomHooks";

import { useNavigate } from "react-router-dom";
import BottomButtons, { BottomButtonGroup } from "../Components/BottomButtons";
import {
  addUserScores,
  fetchCompeteLetters2,
  getGameStringFrmFile,
  updateGroup,
  updateUser,
} from "../Apis/AppApis";
import Loading from "../Components/Loading";
import CompetePopup from "../Components/CompetePopup";
import { userSchema } from "../Utilities/Validations";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import FormInput from "../Components/FormInput";

import {
  CONSONANTS,
  CURRENT_DATE,
  GMT_HOUR,
  GMT_MINUTE,
  SECONDS_AT_END,
  SECONDS_PER_LETTER,
  STRING,
  VOWELS,
} from "../Utilities/Constants";
import {
  getMidNightGMT,
  getCurrentTimeInGMT,
  isTimeDifferenceLessThanSecond,
} from "../Utilities/MyUtilities";
import RadioButtonsGrp from "../Components/RadioButtonsGrp";
import SimpleAlert from "../Components/SimpleAlert";
import moment from "moment";
import AppSlider from "../Components/Carousel";
import DiscreteSliderSteps from "../Components/ReactSlider";

const practiceLevels = [
  { label: "Beginner", value: "Beginner" },
  { label: "Intermediate", value: "Intermediate" },
  { label: "Advanced", value: "Advanced" },
];
const kidsLevels = [
  { label: "Pre-K - no word scrambling", value: "Pre-K" },
  { label: "Kindergarten - each word scrambled", value: "Kindergarten" },
  {
    label: "First grade - letters scrambled but weighted",
    value: "First Grade",
  },
  {
    label: "Second grade - letters scrambled but weighted",
    value: "Second Grade",
  },
  { label: "Third grade - random letters", value: "Third Grade" },
];
const dictionaries = [
  { label: "Collins", value: "Collins" },
  { label: "OSPD", value: "OSPD" },
  { label: "Sight Words", value: "Sight Words" },
];

export default function Options() {
  const [initialInfo, setInitialInfo] = useState({
    name: "",
    city: "",
    uuid: "",
    level: "Practice",
    levelComplexity: "Intermediate",
    show: false,
  });
  const methods = useForm({
    resolver: yupResolver(userSchema),
  });

  const [competeInfo, setCompeteInfo] = useState({
    date: "",
    secondsPerLetter: "",
    secondsAtTheEnd: "",
    consonants: "",
    vowels: "",
    gameofDayString: "",
  });
  const [loading, setLoading] = useState(true);
  const [activeCompete, setActiveCompete] = useState(true);
  const [showCompeteModal, setShowCompeteModal] = useState(false);
  const [competeMessages, setCompeteMessages] = useState("");
  const [remainingTime, setRemainingTime] = useState({
    hours: 0,
    minutes: 0,
  });
  const [showAlert, setShowAlert] = useState(false);
  const [simpleAlertMsg, setSimpleAlertMsg] = useState("");
  const [simpleAlertMsg2, setSimpleAlertMsg2] = useState("");
  const [selectedDictionary, setSelectedDictionary] = useState("Collins");

  const navigate = useNavigate();

  UseEffectUtility(() => {
    const name = localStorage.getItem("NAME");
    const city = localStorage.getItem("CITY");
    const level = localStorage.getItem("LEVEL");
    const uuid = localStorage.getItem("UUID");
    const levelComplexity = localStorage.getItem("L_COMPLEXITY");
    const dictionary = localStorage.getItem("DICTIONARY");
    if (!dictionary) {
      setSelectedDictionary("Collins");
    } else {
      setSelectedDictionary(dictionary);
    }

    if (!levelComplexity) {
      localStorage.setItem("L_COMPLEXITY", "Intermediate");
    }

    getCompeteLetters(uuid);
    methods.reset({
      name: name,
      city: city,
    });

    setInitialInfo({
      ...initialInfo,
      name: name ? name : "",
      city: city ? city : "",
      level: level ? level : "Practice",
      levelComplexity: levelComplexity
        ? levelComplexity
        : level === "Compete"
        ? "Advanced"
        : "Intermediate",
      uuid: uuid,
    });
  }, []);

  const fetchGameString = async () => {
    const apiResponse = await getGameStringFrmFile();
    const lines = apiResponse.trim().split("\n");

    const competeInfoHolder = [];

    lines.forEach((line) => {
      const values = line.split(",");
      const formattedObject = {
        date: values[0],
        secondsPerLetter: values[1],
        secondsAtTheEnd: values[2],
        consonants: values[3],
        vowels: values[4],
        gameofDayString: values.slice(5).join(""),
      };
      competeInfoHolder.push(formattedObject);
    });

    const filteredData = competeInfoHolder.filter(
      (gameString) => gameString.date === moment().format("MM/DD/YY")
    );
    console.log("filteredData[0] ", filteredData[0]);

    if (filteredData[0]) {
      return filteredData[0];
    } else {
      const staticData = {
        date: CURRENT_DATE,
        secondsPerLetter: SECONDS_PER_LETTER,
        secondsAtTheEnd: SECONDS_AT_END,
        consonants: CONSONANTS,
        vowels: VOWELS,
        gameofDayString: STRING,
      };

      return staticData;
    }
  };

  const getCompeteLetters = async (uuid) => {
    try {
      const isCompeteDayExist = competeDayExist1();
      // if (!isCompeteDayExist) setLoading(false);

      // const response = await fetchCompeteLetters2(uuid);
      const response = await fetchGameString();

      setCompeteInfo(response);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setCompeteInfo({
        date: CURRENT_DATE,
        secondsPerLetter: SECONDS_PER_LETTER,
        secondsAtTheEnd: SECONDS_AT_END,
        consonants: CONSONANTS,
        vowels: VOWELS,
        gameofDayString: STRING,
      });

      // setCompeteLetters(STRING);
    }
  };

  function calculateRemainingTime(from, to) {
    const diffInMs = Math.abs(new Date(to) - new Date(from));

    const hours = Math.floor(diffInMs / 3600000); // 1 hour = 3600000 ms
    const minutes = Math.floor((diffInMs % 3600000) / 60000); // 1 minute = 60000 ms
    return { hours, minutes };
  }

  const competeDayExist1 = () => {
    const lastGameTimestamp = localStorage.getItem("lastGameTimestamp");
    const upcomingMidnight = localStorage.getItem("UPCOMING_MID_NIGHT");
    const currentTimeStamp = new Date().toUTCString();

    if (!lastGameTimestamp) return false;
    // const upcomingMidnight = calculateUpcomingMidnight();

    const lastGameDate = new Date(lastGameTimestamp);
    const currentGameDate = new Date(currentTimeStamp);
    const upcomgMidnight = new Date(upcomingMidnight);

    if (
      lastGameDate &&
      currentGameDate &&
      upcomgMidnight &&
      currentGameDate >= upcomgMidnight &&
      lastGameDate < upcomgMidnight
    ) {
      // const newCurrentTimeStamp = new Date(Date.now()).toUTCString();
      // localStorage.setItem("lastGameTimestamp", newCurrentTimeStamp);
      setActiveCompete(true);
      console.log("true");

      return true;
    } else {
      const { hours, minutes } = calculateRemainingTime(
        currentGameDate,
        upcomingMidnight
      );
      setRemainingTime({
        hours,
        minutes,
      });
      console.log("false");

      setActiveCompete(false);
      return false;
    }
  };

  const handleChange = (name, e) => {
    if (name === "name") {
      setInitialInfo({ ...initialInfo, name: e.target.value });
    } else {
      setInitialInfo({ ...initialInfo, city: e.target.value });
    }
  };

  const onClickPlay = async (values) => {
    const firstPlay = localStorage.getItem("FIRST_PLAY");

    if (initialInfo.level === "Compete" && !activeCompete) {
      const midNightGMT = getMidNightGMT();
      setShowCompeteModal(true);
      setCompeteMessages(
        `You can only compete once per day.  The day starts at midnight GMT. The next game will begin in ${remainingTime?.hours} hours and ${remainingTime?.minutes} minutes.`

        // `You can only compete once per day and the next game begins in ${midNightGMT?.remainingTimeUntilMidnight?.hours} hours and ${midNightGMT?.remainingTimeUntilMidnight?.minutes} minutes`
      );
      return;
    }
    let uuID;
    const nameHolder = localStorage.getItem("NAME");
    const cityHolder = localStorage.getItem("CITY");
    const uuIDHolder = localStorage.getItem("UUID");

    if (!initialInfo.uuid) {
      // uuID = uuidv4();
      const groupId = localStorage.getItem("GROUP_ID");
      uuID = await addUser(uuID);

      if (groupId) {
        setLoading(true);

        const response = await updateGroup(groupId, uuID);
        setLoading(false);
      }
    }

    if (
      uuIDHolder &&
      (nameHolder !== initialInfo.name || cityHolder !== initialInfo.city)
    ) {
      try {
        setLoading(true);
        const updateUserReponse = await updateUser(
          uuIDHolder,
          initialInfo.name,
          initialInfo.city
        );

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    localStorage.setItem("UUID", initialInfo.uuid ? initialInfo.uuid : uuID);
    localStorage.setItem("NAME", initialInfo.name);
    localStorage.setItem("CITY", initialInfo.city);
    localStorage.setItem("LEVEL", initialInfo.level);
    localStorage.setItem("L_COMPLEXITY", initialInfo.levelComplexity);
    localStorage.setItem("DICTIONARY", selectedDictionary);

    if (!firstPlay) {
      localStorage.setItem("FIRST_PLAY", "Played");
      setShowAlert(true);
      setSimpleAlertMsg(
        "Make words as quickly as possible.  When all 12 tiles fill up you will have a few seconds before the game ends."
      );
      if (initialInfo.level !== "Compete") return;
      setSimpleAlertMsg2(
        `Today's game will be ${competeInfo?.vowels} vowel(s) followed by ${competeInfo?.consonants} consonant(s). You will have ${competeInfo?.secondsPerLetter} second(s) between letters and ${competeInfo?.secondsAtTheEnd} second(s) after the 12th letter.  Click OK to start the game. You can only complete once per day GMT.`
      );

      return;
    }
    if (initialInfo.level === "Compete") {
      setShowAlert(true);
      setSimpleAlertMsg2(
        `Today's game will be  ${competeInfo?.vowels} vowel(s) followed by ${
          competeInfo?.consonants === "0" ? "random" : competeInfo?.consonants
        } consonant(s). You will have ${
          competeInfo?.secondsPerLetter
        } second(s) between letters and ${
          competeInfo?.secondsAtTheEnd
        } second(s) after the 12th letter.  Click OK to start the game. You can only complete once per day GMT.`
      );
      return;
    }

    goToPlay();
  };

  const goToPlay = () => {
    navigate("/Play", {
      state: {
        compete: initialInfo?.level === "Compete" ? true : false,
        competeInfo: competeInfo,
      },
    });
  };

  const addUser = async (uuid) => {
    try {
      const scoreArray = [{ score: 0, level: "" }];
      const userInfo = {
        name: initialInfo?.name,
        city: initialInfo?.city,
        uuId: uuid,
        scores: scoreArray,
      };
      setLoading(true);
      const response = await addUserScores(userInfo);

      setLoading(false);
      return response?.message?.id;
    } catch (error) {
      setLoading(false);
    }
  };

  const handleLevelSelect = (newLevel, lvlComplexity) => {
    if (newLevel === "Compete") {
      setInitialInfo({
        ...initialInfo,
        levelComplexity: "Advanced",
        level: newLevel,
      });
    } else {
      setInitialInfo({
        ...initialInfo,
        ...(lvlComplexity && { levelComplexity: lvlComplexity }),
        level: newLevel,
      });
    }
  };
  useEffect(() => {}, [initialInfo]);
  const handleSelectComplexity = (complexity) => {
    setInitialInfo({
      ...initialInfo,
      levelComplexity: complexity,
    });
  };

  return (
    <div>
      <Loading load={loading} />
      <SimpleAlert
        message={simpleAlertMsg}
        secondMessage={simpleAlertMsg2}
        show={showAlert}
        setShow={setShowAlert}
        nextPage={goToPlay}
      />
      <CompetePopup
        handleOk={() => setShowCompeteModal(false)}
        visible={showCompeteModal}
        message={competeMessages}
      />

      <div>
        <Navbar title={"Options"} backPage='/' />

        <div className='options-container'>
          <h5 className='mb-1 promotion-txt text-center'>
            Please enter a name and city for the
          </h5>
          <h5 className='mb-2 promotion-txt'>
            leaderboard and select your dictionary.
          </h5>
          <FormProvider {...methods}>
            <Form className='d-flex direction-row align-items-center justify-content-around'>
              {/* <div className='d-flex direction-row align-items-center justify-content-between'> */}
              <div className='input-field-width'>
                {/* <p className='fw-bolder text-center m-0 mb-1 p-0'>Name</p> */}
                <FormInput
                  id='name'
                  name='name'
                  defaultValue={initialInfo?.name}
                  value={initialInfo.name}
                  placeholder='Name'
                  onChange={(e) => handleChange("name", e)}
                />
              </div>
              {/* <div className='d-flex direction-row align-items-center justify-content-between'> */}
              <div className='input-field-width'>
                {/* <p className='fw-bolder text-center m-0 mb-1 p-0'>City</p> */}
                <FormInput
                  id='city'
                  name='city'
                  value={initialInfo.city}
                  placeholder='City'
                  onChange={(e) => handleChange("city", e)}
                />
              </div>
            </Form>
          </FormProvider>
          <RadioButtonsGrp
            title='Select Dictionary'
            options={dictionaries}
            selectedOption={selectedDictionary}
            onChange={(e) => {
              if (e.target.value === "Sight Words") {
                handleLevelSelect("Practice", "Pre-K");
              } else if (
                initialInfo?.levelComplexity !== "Beginner" ||
                initialInfo?.levelComplexity !== "Intermediate" ||
                initialInfo?.levelComplexity !== "Advanced"
              ) {
                handleSelectComplexity("Beginner");
              }

              setSelectedDictionary(e.target.value);
            }}
            direction='row'
          />

          <ButtonGroup
            style={{
              width: selectedDictionary === "Sight Words" ? "40%" : "90%",
            }}
          >
            <Button
              onClick={() => handleLevelSelect("Practice")}
              variant={
                initialInfo?.level === "Practice"
                  ? "primary"
                  : "outline-primary"
              }
            >
              {"Practice"}
              {initialInfo?.level === "Practice" && (
                <FontAwesomeIcon icon={faCheck} className='tick-icon' />
              )}
            </Button>
            {selectedDictionary !== "Sight Words" && (
              <Button
                className={
                  selectedDictionary === "Sight Words" && `disabled-button`
                }
                onClick={() => handleLevelSelect("Compete")}
                variant={
                  initialInfo?.level === "Compete"
                    ? "primary"
                    : "outline-primary"
                }
                disabled={selectedDictionary === "Sight Words"}
              >
                {"Compete"}
                {initialInfo?.level === "Compete" && (
                  <FontAwesomeIcon icon={faCheck} className='tick-icon' />
                )}
              </Button>
            )}
          </ButtonGroup>
        </div>
        <div className='d-flex direction-row justify-content-around mt-2'>
          <div>
            <RadioButtonsGrp
              title='Select Level'
              options={
                selectedDictionary === "Sight Words"
                  ? kidsLevels
                  : practiceLevels
              }
              selectedDictionary={selectedDictionary}
              selectedOption={initialInfo?.levelComplexity}
              onChange={(e) => {
                handleSelectComplexity(e.target.value);
              }}
              selectedLevel={initialInfo?.level}
            />
            {/* <AppSlider gameInfo={initialInfo} /> */}
            {selectedDictionary !== "Sight Words" && (
              <DiscreteSliderSteps gameInfo={initialInfo} />
            )}
          </div>
          {selectedDictionary !== "Sight Words" ? <div /> : null}
          {/* <RadioButtonsGrp
            title='Select Dictionary'
            options={dictionaries}
            selectedOption={selectedDictionary}
            onChange={(e) => {
              setSelectedDictionary(e.target.value);
            }}
          /> */}
        </div>

        <div className='mt-5'>
          <BottomButtonGroup
            buttons={[
              {
                label: "Play",
                icon: "-solid fa-play",
                onClick: () => methods.handleSubmit(onClickPlay)(),
              },

              {
                label: "Friends Group",
                icon: "-solid fa-people-group",
                onClick: () => navigate("/FriendsGroup"),
              },
              {
                label: "Help",
                icon: "-solid fa-question",
                onClick: () => {
                  const helpContent =
                    selectedDictionary === "Sight Words"
                      ? "Sight Words"
                      : "Options";
                  navigate("/Help", {
                    state: { helpContent },
                  });
                },
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}
