import React from "react";

function Letters(props) {
  const rows = [];
  const itemsPerRow = 4;
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  for (let i = 0; i < props.word.length; i += itemsPerRow) {
    const rowItems = props.word.slice(i, i + itemsPerRow);
    rows.push(rowItems);
  }

  return (
    <div className='letters-container'>
      {rows.map((row, rowIndex) => (
        <div key={rowIndex} className='letters-row'>
          {row.map((item, itemIndex) => (
            <button
              key={itemIndex}
              className='letters-item'
              disabled={props.disabled}
              onClick={() =>
                isMobile
                  ? undefined
                  : props.onClickLetter(item, rowIndex * 4 + itemIndex)
              }
              onTouchStart={() =>
                isMobile
                  ? props.onClickLetter(item, rowIndex * 4 + itemIndex)
                  : undefined
              }
            >
              <p className='text-uppercase'>{item}</p>
            </button>
          ))}
        </div>
      ))}
    </div>
  );
}

export default Letters;
