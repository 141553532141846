import React from "react";
import FormInput from "../Components/FormInput";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Form, Button } from "react-bootstrap";

const validationSchema = yup.object({
  name: yup
    .string()
    .required("Please enter Name")
    .matches(/^[^0-9]*$/, "Name cannot contain numbers")
    .max(10, "Name is too long"),
  city: yup
    .string()
    .required("Please enter City")
    .matches(/^[^0-9]*$/, "City cannot contain numbers")
    .max(10, "City name is too long"),
});

export default function UserInfo({ initialInfo, handleChange, close }) {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  return (
    <div className='p-4'>
      <p className='mt-2 fw-normal fs-5 game-rule-text'>
        To play, simply tap on available letters to form words before the tiles
        fill up. If the word is correct, the letters disappear. A letter can be
        used more than once, and longer words score higher; “BANANA” scores
        higher than “BAN”. After all the tiles are filled, you have a few
        seconds to submit a word or the game ends. There are three levels of
        play to choose from. Name and city is requested for the leaderboard.
      </p>
      <div className='centered-div'>
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(close)}>
            <div>
              <p className='fw-bolder text-center mb-3'>Enter your name :</p>
              <FormInput
                id='name'
                name='name'
                value={initialInfo.name}
                placeholder='Name'
                onChange={(e) => handleChange("name", e)}
              />
            </div>
            <div>
              <p className='fw-bolder text-center my-3'>Enter your city :</p>
              <FormInput
                id='city'
                name='city'
                value={initialInfo.city}
                placeholder='City'
                onChange={(e) => handleChange("city", e)}
              />
            </div>
            <div className='d-flex flex-row justify-content-center mt-3'>
              <Button type='submit' className='w-100 btn-ok' id='ok'>
                Ok
              </Button>
            </div>
          </Form>
        </FormProvider>
      </div>
    </div>
  );
}
