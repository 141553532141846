import { useEffect } from "react";

const RouteContainer = ({ children, tabTitle }) => {
  useEffect(() => {
    document.title = tabTitle;
  }, [tabTitle]);

  return children;
};

export default RouteContainer;
