import React from "react";
import Form from "react-bootstrap/Form";
import { useFormContext } from "react-hook-form";

export default function FormInput({
  label,
  placeholder,
  onChange,
  value,
  name,
  id,
  type = "text",
  className,
  ...rest
}) {
  const methods = useFormContext();
  const fieldRegister = methods.register(
    name,
    type !== "select" && { onChange: onChange ? onChange : () => {} }
  );
  const error = methods?.formState?.errors[name]?.message;

  return (
    <div>
      <Form.Control
        value={value}
        type='text'
        placeholder={placeholder}
        name={name}
        className={className}
        id={id}
        {...fieldRegister}
        {...rest}
      />
      {error ? (
        <p className='error-text p-0 m-0'>{error}</p>
      ) : (
        <p className='error-text p-0 m-0' style={{ color: "#fff" }}>
          a
        </p>
      )}
    </div>
  );
}
