import * as yup from "yup";
export const userSchema = yup.object({
  name: yup
    .string()
    .required("Please enter Name")
    .matches(/^[^0-9]*$/, "Name cannot contain numbers")
    .max(15, "Name is too long"),
  city: yup
    .string()
    .required("Please enter City")
    .matches(/^[^0-9]*$/, "City cannot contain numbers")
    .max(15, "City name is too long"),
});
export const groupSchema = yup.object({
  groupName: yup
    .string()
    .required("Please enter Group Name")
    .matches(/^[^0-9]*$/, "Group Name cannot contain numbers")
    .max(15, "Group Name is too long"),
});
