import React from "react";

export default function InfoRow({ label, value }) {
  return (
    <div className='info-row'>
      <p>{label}:</p>
      <p>{value}</p>
    </div>
  );
}
