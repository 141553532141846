import React from "react";
import { formatDate, truncateString } from "../Utilities/MyUtilities";

export default function MyScore({ scoreList }) {
  return (
    <table className='top-scores-table'>
      <thead>
        <tr>
          <th>Name</th>
          <th>Score</th>
          <th>Level</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        {scoreList?.map((e, i) => {
          return (
            <tr key={i}>
              <td>{truncateString(e.name, 15)}</td>
              <td>{e.score}</td>
              <td>{e.level}</td>
              <td>{formatDate(e.date)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
