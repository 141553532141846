import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import env from "../env";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserInfo from "./UserInfo";
import BottomButtons, {
  BottomButtonGroup,
  ButtonGroup,
} from "../Components/BottomButtons";
import { useLocation } from "react-router-dom";

export default function Main() {
  const location = useLocation();
  let groupId = localStorage.getItem("GROUP_ID");
  let groupName = localStorage.getItem("GROUP_NAME");

  const [initialInfo, setInitialInfo] = useState({
    name: "",
    city: "",
    uuid: "",
    level: "Beginner",
    show: false,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const groupName = searchParams.get("name");
    const groupId = searchParams.get("id");

    if (groupId) {
      navigate("/FriendsGroup", {
        state: {
          groupId,
          groupName,
        },
      });
    }
    fetchDataFrmLocalStrg();
  }, []);

  const fetchDataFrmLocalStrg = () => {
    const uuid = localStorage.getItem("UUID");
    const name = localStorage.getItem("NAME");
    const city = localStorage.getItem("CITY");
    const level = localStorage.getItem("LEVEL");

    if (!level) {
      localStorage.setItem("LEVEL", "Practice");
      setInitialInfo({ ...initialInfo, uuid: uuid, name: name, city: city });
    } else {
      setInitialInfo({ ...initialInfo, level: level });
      setInitialInfo({
        ...initialInfo,
        uuid: uuid,
        name: name,
        city: city,
        level: level,
      });
    }
  };

  const start = () => {
    if (!initialInfo.uuid) {
      setInitialInfo({ ...initialInfo, show: true });
    } else if (!initialInfo.name && !initialInfo.city) {
    } else {
      navigate("/Play");
    }
  };

  const close = (values) => {
    console.log("values ", values);
    localStorage.setItem("UUID", uuidv4());
    localStorage.setItem("NAME", values.name);
    localStorage.setItem("CITY", values.city);
    setInitialInfo({ ...initialInfo, uuid: uuidv4(), show: false });
    navigate("/Play");
  };

  const handleChange = (name, e) => {
    if (name === "name") {
      setInitialInfo({ ...initialInfo, name: e.target.value });
    } else {
      setInitialInfo({ ...initialInfo, city: e.target.value });
    }
  };

  if (initialInfo.show) {
    return (
      <UserInfo
        initialInfo={initialInfo}
        handleChange={handleChange}
        close={close}
      />
    );
  } else {
    return (
      <div className='container vh-100'>
        <p className='title fw-bold color-primary fst-italic text-center'>
          MaxWord
        </p>
        <p className='fw-bold text-center promo-text m-0'>
          Challenge friends and the world daily
        </p>
        <p className='fw-bold text-center promo-text m-0'>Practice anytime.</p>

        <div class='d-flex justify-content-center my-5'>
          <img
            className='rounded'
            src={require("../Assets/imgs/MWicon.png")}
            alt='logo'
          />
        </div>
        <p className='fw-bolder text-center promo-text'>{env.APP_VERSION}</p>
        <BottomButtonGroup
          buttons={[
            {
              label: "Play",
              icon: "-solid fa-play",
              onClick: () => navigate("/Options"),
            },
            {
              label: "Top Scores",
              icon: "-solid fa-bars",
              onClick: () =>
                navigate("/TopScores", {
                  state: { gameDay: true },
                }),
            },
            {
              label: "Friends Group",
              icon: "-solid fa-people-group",
              onClick: () => navigate("/FriendsGroup"),
            },
            // groupId && {
            //   label: "Friends Scores",
            //   icon: "-solid fa-people-group",
            //   onClick: () =>
            //     navigate("/TopScores", {
            //       state: { groupName: groupName, groupId: groupId },
            //     }),
            // },
            {
              label: "Help",
              icon: "-solid fa-question",
              onClick: () => navigate("/Help"),
            },
          ]}
        />
      </div>
    );
  }
}
