import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { formatDate, truncateString } from "../Utilities/MyUtilities";

export default function GameDayScore({ item, activeKey }) {
  return (
    <Accordion defaultActiveKey={activeKey}>
      <Accordion.Item eventKey={activeKey}>
        <Accordion.Header>{`${item.date}   GMT`}</Accordion.Header>
        <Accordion.Body>
          <div className='table-container'>
            <table className='top-scores-table'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>City</th>
                  <th>Score</th>
                  <th>Club</th>
                </tr>
              </thead>
              <tbody>
                {item?.list?.map((score, i) => {
                  if (!score?.score) return;
                  return (
                    <tr
                      key={i}
                      style={{
                        backgroundColor:
                          score?.dictionary !== "OSPD" ? "#D3D3D3" : "orange",
                      }}
                    >
                      <td>{truncateString(score.name, 15)}</td>
                      <td>{score.city}</td>
                      <td>{score.score}</td>
                      <td>{score?.groupName ? score?.groupName : "-"}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
