import React from "react";
import Navbar from "./Navbar";

export default function KidsHelp() {
  return (
    <div>
      <Navbar title='Help' backPage='/' />
      <div className='container-help pl-5 pr-5'>
        <p className='help-text-bold'>
          Step 1: Choose Your Students' Grade Level
        </p>
        <p className='help-text-regular'>
          Start by selecting the appropriate grade level for your students. This
          ensures that the game's difficulty and content are tailored to their
          learning stage.
        </p>

        <p className='help-text-bold'>Grade Levels Explained:</p>
        <ul className='help-text-list'>
          <li>
            Pre-K: Words appear in their complete form (e.g., "CATDOG" for "CAT"
            and "DOG").
          </li>

          <li>
            Kindergarten (K): Each word is scrambled independently (e.g.,
            "ACTGDO" for "CAT" and "DOG").
          </li>
          <li>
            First Grade: Sight words are fully scrambled, randomized, and
            weighted for complexity.
          </li>
          <li>
            Second Grade: Similar to First Grade, with an added challenge in
            word complexity.
          </li>

          <li>
            Third Grade: A higher difficulty level with completely random
            letters and no weighting.
          </li>
        </ul>

        <p className='help-text-bold'>Note:</p>
        <ul className='help-text-list'>
          <li>
            This version is designed for practice and does not include a Compete
            mode. It's focused on helping kids enhance their Sight Words skills
            in a fun, engaging way.
          </li>

          <li>
            When all the letters are filled, the user will have 20 seconds to
            enter a correct word before the game ends.
          </li>
          <li>
            All words are from the Sight Words list for TK to 3rd grade. More
            advanced kids can play the adult version which uses the full
            dictionary.
          </li>
        </ul>
        <p className='help-text-bold'>Join Our Community & Get Support:</p>
        <ul className='help-text-list'>
          <li>
            Discuss: Share your experiences and tips on our Facebook page:{" "}
            <a
              href='https://www.facebook.com/maxwordapp/'
              style={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              MaxWord App on Facebook
            </a>
          </li>
          <li>
            Support and Feedback: We're here to help! For assistance,
            suggestions, or other inquiries, contact us a{" "}
            <a
              href='mailto:vimster@gmail.com'
              style={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              vimster@gmail.com
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
