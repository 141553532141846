export const Kindergarten = [
  "A",
  "AND",
  "AWAY",
  "BIG",
  "BLUE",
  "CAN",
  "COME",
  "DOWN",
  "FIND",
  "FOR",
  "FUNNY",
  "GO",
  "HELP",
  "HERE",
  "I",
  "IN",
  "IS",
  "IT",
  "JUMP",
  "LITTLE",
  "LOOK",
  "MAKE",
  "ME",
  "MY",
  "NOT",
  "ONE",
  "PLAY",
  "RED",
  "RUN",
  "SAID",
  "SEE",
  "THE",
  "THREE",
  "TO",
  "TWO",
  "UP",
  "WE",
  "WHERE",
  "YELLOW",
  "YOU",
  "ALL",
  "AM",
  "ARE",
  "AT",
  "ATE",
  "BE",
  "BLACK",
  "BROWN",
  "BUT",
  "CAME",
  "DID",
  "DO",
  "EAT",
  "FOUR",
  "GET",
  "GOOD",
  "HAVE",
  "HE",
  "INTO",
  "LIKE",
  "MUST",
  "NEW",
  "NO",
  "NOW",
  "ON",
  "OUR",
  "OUT",
  "PLEASE",
  "PRETTY",
  "RAN",
  "RIDE",
  "SAW",
  "SAY",
  "SHE",
  "SO",
  "SOON",
  "THAT",
  "THERE",
  "THEY",
  "THIS",
  "TOO",
  "UNDER",
  "WANT",
  "WAS",
  "WELL",
  "WENT",
  "WHAT",
  "WHITE",
  "WHO",
  "WILL",
  "WITH",
];
