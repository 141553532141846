import React, { useEffect, useRef } from "react";

export default function UseEffectUtility(methods, depedencies) {
  const effectRan = useRef(false);
  useEffect(() => {
    if (effectRan.current || process.env.NODE_ENV !== "development") {
      methods();
    }

    return () => {
      effectRan.current = true;
    };
  }, depedencies);

  return <div></div>;
}
