import React from "react";
import Button from "./Button";

import { Button as RCTButton } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const BottomButtonGroup = ({ buttons }) => {
  return (
    <div className='button-group'>
      {buttons.map((button, index) => {
        if (!button?.onClick) return;
        return (
          <RCTButton
            onClick={button.onClick}
            key={index}
            className='custom-button'
          >
            <FontAwesomeIcon className='fa' icon={`fa${button.icon}`} />
            <span className='button-label'>{button.label}</span>
          </RCTButton>
        );
      })}
    </div>
  );
};

export default function BottomButtons({
  firstButton,
  secondButton,
  thirdButton,
  fourthButton,
}) {
  return (
    <div className='d-flex play-btn-container'>
      {firstButton && (
        <Button
          className='mx-1'
          topIcon={firstButton.icon}
          label={firstButton.title}
          onClick={firstButton.onClick}
          labelClass={"btn-text-size"}
        />
      )}
      {secondButton && (
        <Button
          className='mx-1'
          topIcon={secondButton.icon}
          label={secondButton.title}
          onClick={secondButton.onClick}
          labelClass={"btn-text-size"}
        />
      )}
      {thirdButton && (
        <Button
          className='mx-1'
          topIcon={thirdButton.icon}
          label={thirdButton.title}
          onClick={thirdButton.onClick}
          labelClass={"btn-text-size"}
        />
      )}
      {fourthButton && (
        <Button
          className='mx-1'
          topIcon={fourthButton.icon}
          label={fourthButton.title}
          onClick={fourthButton.onClick}
          labelClass={"btn-text-size"}
        />
      )}
    </div>
  );
}
