import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function GameOverModal({ visible, handleOk }) {
  if (!visible) return null;
  return (
    <>
      <Modal show={visible} centered>
        <Modal.Body className='game-over-modal-content'>
          <h3>Game Over</h3>
          {/* <h4>You're a wordplay wizard!</h4> */}
        </Modal.Body>
        {/* <div className='end-game-modal-btn-container d-flex justify-content-center'>
          <Button className='btn-yes' onClick={handleOk}>
            See Your Practice Stats
          </Button>
        </div> */}
      </Modal>
    </>
  );
}

export default GameOverModal;
