import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function EndGameModal({ visible, handleYes, handleNo }) {
  if (!visible) return null;
  return (
    <>
      <Modal show={visible} centered>
        <Modal.Body className='end-game-modal-cont'>
          <h3>Game Over!</h3>

          {/* <p>Do you want to post your score to our site?</p> */}
          <Button className='mt-5 w-50' onClick={handleYes}>
            Ok
          </Button>
        </Modal.Body>
        {/* <div className='end-game-modal-btn-container'>
          <Button className='btn-no' onClick={handleNo}>
            No
          </Button>
        </div> */}
      </Modal>
    </>
  );
}

export default EndGameModal;
