import React from "react";
import Button from "react-bootstrap/Button";

export default function DeleteSubmitContainer({
  handleDelete,
  handleClear,
  handleSubmit,
  submitBtnTitle,
  disableBtns,
}) {
  return (
    <div className='button-container'>
      <div className='action-buttons'>
        <Button
          disabled={disableBtns}
          onClick={handleDelete}
          className='custom-button delete-button'
        >
          Del
        </Button>
        <Button
          disabled={disableBtns}
          onClick={handleClear}
          className='custom-button clear-button'
        >
          Clear
        </Button>
      </div>
      <Button
        disabled={disableBtns}
        onClick={handleSubmit}
        className='custom-button submit-button'
      >
        {submitBtnTitle}
      </Button>
    </div>
  );
}
