export const Pre_K = [
  "BBBB",
  "A",
  "AND",
  "AWAY",
  "BIG",
  "BLUE",
  "CAN",
  "COME",
  "DOWN",
  "FIND",
  "FOR",
  "FUNNY",
  "GO",
  "HELP",
  "HERE",
  "I",
  "IN",
  "IS",
  "IT",
  "JUMP",
  "LITTLE",
  "LOOK",
  "MAKE",
  "ME",
  "MY",
  "NOT",
  "ONE",
  "PLAY",
  "RED",
  "RUN",
  "SAID",
  "SEE",
  "THE",
  "THREE",
  "TO",
  "TWO",
  "UP",
  "WE",
  "WHERE",
  "YELLOW",
  "YOU",
];
