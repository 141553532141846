import React from "react";
import Navbar from "./Navbar";

export default function OptionsHelp() {
  return (
    <div>
      <Navbar title='Help' backPage='/' />
      <div className='container-help pl-5 pr-5'>
        <p className='help-text-bold'>Play Screen:</p>
        <p className='help-text-regular'>
          Name and City fields are used only for the leaderboard. Select the
          dictionary of your choice -: Collins or OSPD (North America).{" "}
        </p>

        <p className='help-text-bold'>Modes:</p>
        <ol>
          <li>Practice: Play as much as you want.</li>
          <ul className='unfilled-bullet-list'>
            <li>Select Beginner (slow), Intermediate, or Advanced (fast).</li>
            <li>
              Choose if you want more vowels or consonants by moving the slider.
            </li>
          </ul>
          <li>
            Compete: Take on a daily challenge against all users worldwide.
          </li>
          <ul className='unfilled-bullet-list'>
            <li>Each user gets the same string of letters for that day.</li>
            <li>Daily game begins and ends at midnight GMT.</li>
            <li>
              The level of difficulty fluctuates daily with varying numbers of
              vowels, consonants, and the amount of time you have.
            </li>
          </ul>
          <li>
            Friends Group - Same as Compete, but only view the scores of your
            friends.
          </li>
          <ul className='unfilled-bullet-list'>
            <li>Create a group.</li>
            <li>Share the URL with your friends.</li>
            <li>See only your friends' scores.</li>
          </ul>
        </ol>

        <p className='help-text-bold'>Scores:</p>
        <ol>
          <ul className='unfilled-bullet-list'>
            <li>2 and 3 letter words, word length multiplied by 1</li>
            <li>4 or 5 letter words, word length multiplied by 2</li>
            <li>6 or 7 letter words, word length multiplied by 4</li>
            <li>{">"} 7 letter words, word length multiplied by 8</li>
          </ul>
        </ol>

        <p className='help-text-regular'>
          Discuss the game on Facebook -{" "}
          <a
            href='https://www.facebook.com/maxwordapp/'
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            https://www.facebook.com/maxwordapp/
          </a>
        </p>

        <p className='help-text-regular'>
          Missing words, feedback or other issues? Reach out to us at{" "}
          <a
            href='mailto:maxword@vimware.com'
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            veronicamadsen@yahoo.com
          </a>
          . We appreciate your insights and suggestions!
        </p>
      </div>
    </div>
  );
}
