import React from "react";

export default function WordList({ words, label }) {
  const scrollThreshold = 3;

  return (
    <div>
      <div className='d-flex align-items-center'>
        <p className='m-0'> {label} </p>
        <p className='m-0 text-center'> {`(${words?.length})`}</p>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          maxWidth: "180px",
          overflowX: "scroll",
        }}
      >
        {words?.map((word, i) => (
          <div style={{ marginRight: 3, height: 40 }} key={i}>
            <div>
              <p className='m-0 text-center text-uppercase'>{word},</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
