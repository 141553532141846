import moment from "moment";
import { GMT_HOUR, GMT_MINUTE } from "./Constants";

export const formatDate = (date) => {
  if (!date) return "";
  return moment(date).format("MMM-DD-YYYY");
};

export const getRandomInt = () => {
  const min = Math.ceil(1);
  const max = Math.floor(10);
  const random = Math.floor(Math.random() * (max - min)) + min;
  console.log("random ", random);
  return random;
};

export const filterByDate = (data, targetMonth) => {
  const filteredData = data.filter((item) => {
    const scoreData = new Date(item.createdDate);
    const targetDate = new Date(targetMonth);

    return scoreData >= targetDate;
  });
  return filteredData;
};

export const convertSpaces = (str, toUnderscore = true) => {
  if (toUnderscore) {
    return str.replace(/ /g, "_");
  } else {
    return str.replace(/_/g, " ");
  }
};

export const getMidNightGMT = () => {
  const now = new Date();
  const utcDate = new Date(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds()
  );

  const midnight = new Date(
    utcDate.getUTCFullYear(),
    utcDate.getUTCMonth(),
    utcDate.getUTCDate() + 1,

    GMT_HOUR,
    GMT_MINUTE,
    0
    // 0, // Midnight hours
    // 0, // Midnight minutes
    // 0 // Midnight seconds
  );

  const timeDifference = midnight - utcDate; // Remaining time until midnight in milliseconds

  const hours = Math.floor(timeDifference / 3600000); // Milliseconds to hours
  const minutes = Math.floor((timeDifference % 3600000) / 60000); // Milliseconds to minutes
  const seconds = Math.floor((timeDifference % 60000) / 1000); // Milliseconds to seconds

  return {
    currentTimeInGMT: utcDate,
    remainingTimeUntilMidnight: {
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    },
  };
};

export const getCurrentTimeInGMT = () => {
  const now = new Date();
  const gmtTime = new Date(now.valueOf() + now.getTimezoneOffset() * 60000);
  return gmtTime;
};

export const truncateString = (str, maxLength) => {
  if (str.length > maxLength) {
    return str.slice(0, maxLength);
  }
  return str;
};

export const isTimeDifferenceLessThanSecond = (storeDate, upcomingDate) => {
  const timeDifference = Math.abs(upcomingDate - storeDate);
  console.log("timeDifference ", timeDifference);
  return timeDifference < 1000;
};

export const calculateUpcomingMidnight = () => {
  const now = new Date();
  const nextMidnight = new Date(
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate() + 1, // Add 1 day to the current date
      GMT_HOUR, // Hours
      GMT_MINUTE, // Minutes
      0, // Seconds
      0 // Milliseconds
    )
  );
  return nextMidnight;
};

export function formatCountDownTimer(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
}
