import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import { filterByDate, formatDate } from "../Utilities/MyUtilities";
import { fetchCompeteScores, fetchGroupScores } from "../Apis/AppApis";
import { useLocation } from "react-router-dom";
import Loading from "../Components/Loading";
import GameDayScore from "../Components/GameDayScore";

export default function TopScores() {
  const [topScores, setTopScores] = useState([]);
  const [level, setLevel] = useState("");
  const [loading, setLoading] = useState(true);
  const [gameDayScore, setGameDayScore] = useState([]);

  const { state } = useLocation();

  useEffect(() => {
    allTopScores();
  }, []);

  const allTopScores = async () => {
    setLevel(localStorage.getItem("LEVEL"));
    if (state?.gameDay) {
      const resp = await fetchCompeteScores();
      console.log("resp");
      const sortedData = sortScoresAscending(resp);
      setGameDayScore(sortedData);
    } else {
      const resp = await fetchGroupScores(state?.groupId);
      const sortedData = sortScoresAscending(resp);
      setGameDayScore(sortedData);
      // setTopScores(resp);
    }
    setLoading(false);
  };
  function sortScoresAscending(data) {
    return data.map((dateEntry) => {
      const sortedList = dateEntry.list.sort((a, b) => b.score - a.score);

      return { ...dateEntry, list: sortedList };
    });
  }

  return (
    <div>
      <Loading load={loading} />
      {state?.gameDay ? (
        <Navbar title={`Compete Top Scores`} backPage='/' />
      ) : (
        <Navbar title={`${state?.groupName} Scores`} backPage='/' />
      )}
      <div className='d-flex direction-row justify-content-center align-items-center'>
        Dictionary:
        <div
          className='dictionary-color'
          style={{ backgroundColor: "#D3D3D3" }}
        />
        Collins
        <div
          className='dictionary-color'
          style={{ backgroundColor: "orange" }}
        />
        OSPD
      </div>

      {gameDayScore.map((item, i) => {
        return <GameDayScore activeKey={i} item={item} key={i} />;
      })}
    </div>
  );
}
