import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const Navbar = ({ title, backPage, onBack }) => {
  const navigate = useNavigate();

  return (
    <div className='navbar'>
      <div
        className='back-button'
        onClick={() => {
          if (onBack) {
            onBack();
          } else if (backPage) {
            navigate(`${backPage}`);
          }
        }}
      >
        <FontAwesomeIcon className='color-white' icon='fa-solid fa-home' />
        {/* <FontAwesomeIcon icon={faArrowLeft} className='navbar-icon' /> */}
      </div>
      <div className='site-title'>{title}</div>
      <div />
    </div>
  );
};

export default Navbar;
